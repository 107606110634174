<template>
  <section>
   
      <b-row class="match-height" >
        <template v-for="plan2 in (dataPlan2)">
        <b-col md="4" v-if="level == 0 && plan2.status == 'NonAktif'"  v-bind:key="plan2.id">
        <b-card class="card-app-design">
          <b-img
            src="/img/optimalfull.97d5e41f.png"
            style="width: 200px"
            class="mb-2"
            center
          />
          <div class="design-group text-center">
            <h6 class="text-center mb-2">
              <b>{{ plan2.nama_event }}</b>
            </h6>
            <b-badge variant="success" class="mr-1 text-right">
            Gratis
            </b-badge>
            <b-badge variant="danger" class="mr-1 text-right">
              {{ plan2.jumlah_soal }} Soal
            </b-badge>
            <b-badge variant="danger" class="mr-1 text-right">
              {{ plan2.durasi }} Menit
            </b-badge>
          </div>

          <b-card-group deck>
            <b-card
              bg-variant="light"
              text-variant="black"
              class="text-center"
            >
            <b>{{ formatDate(plan2.tanggal) }},  {{ plan2.waktu }} WIB </b>
            </b-card>
          </b-card-group>
            <b-button variant="primary" block @click="addData(plan2.plans_id_question, plan2.durasi)"> Masuk Ujian </b-button>
        </b-card>
      </b-col>
      <b-col md="4" v-else-if="level == 1 && (plan2.status == 'Aktif' || plan2.status == 'NonAktif')" v-bind:key="plan2.nama">
        <b-card class="card-app-design" v-if="tglSekarang < new Date(plan2.tanggal_akhir)">
          <b-img
            src="/img/optimalfull.97d5e41f.png"
            style="width: 200px"
            class="mb-2"
            center
          />
          <div class="design-group text-center">
            <h6 class="text-center mb-2">
              <b>{{ plan2.nama_event }}</b>
            </h6>
            <b-badge variant="success" class="mr-1 text-right" v-if="plan2.status == 'NonAktif'">
            Gratis
            </b-badge>
            <b-badge variant="primary" class="mr-1 text-right" v-else-if="plan2.status == 'Aktif'">
            Premium
            </b-badge>
            <b-badge variant="danger" class="mr-1 text-right">
              {{ plan2.jumlah_soal }} Soal
            </b-badge>
            <b-badge variant="danger" class="mr-1 text-right">
              {{ plan2.durasi }} Menit
            </b-badge>
          </div>

          <b-card-group deck>
            <b-card
              bg-variant="light"
              text-variant="black"
              class="text-center"
            >
            <b>{{ formatDate(plan2.tanggal) }},  {{ plan2.waktu }} WIB </b>
            </b-card>
          </b-card-group>
            <b-button variant="primary" block @click="addData(plan2.plans_id_question, plan2.durasi)"> Masuk Ujian </b-button>
        </b-card>
      </b-col>
    </template>
      <!-- <b-col md="4" v-for="plan in dataPlan" v-bind:key="plan.id">
        <b-card class="card-app-design">
          <b-img
            src="/img/optimalfull.97d5e41f.png"
            style="width: 200px"
            class="mb-2"
            center
          />
          <div class="design-group text-center">
            <h6 class="text-center mb-2">
              <b>{{ plan.nama_event }}</b>
            </h6>
            <b-badge variant="success" class="mr-1 text-right">
             {{ plan.status }}
            </b-badge>
            <b-badge variant="danger" class="mr-1 text-right">
              {{ plan.jumlah_soal }} Soal
            </b-badge>
            <b-badge variant="danger" class="mr-1 text-right">
              {{ plan.durasi }} Menit
            </b-badge>
          </div>

          <b-card-group deck>
            <b-card
              bg-variant="light"
              text-variant="black"
              class="text-center"
            >
            <b-card-text><b>{{ formatDate(plan.tanggal) }}</b></b-card-text>
            </b-card>

            <b-card
              bg-variant="light"
              text-variant="black"
              class="text-center"
            >
              <b-card-text><b>{{ plan.waktu }}</b></b-card-text>
            </b-card>
          </b-card-group>

          <router-link
            :to="
              '/user/prepare_tryout/' +
              plan.plans_id_question +
              '/' +
              plan.durasi
            "
          >
            <b-button variant="primary" block> Masuk Ujian </b-button>
          </router-link>
        </b-card>
      </b-col> -->
      
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BAvatar,
  BImg,
  BCardText,
  BBadge,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2";
import moment from "moment";
import "moment/locale/id";
export default {
  components: {
    BCard,
    BImg,
    ToastificationContent,
    Swal,
    BCardText,
    BBadge,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BRow,
    BCol,
    StatisticCardHorizontal,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      dataPlan: [],
      dataPlan2: [],
      level: localStorage.getItem("Ulevel"),
      id_session: localStorage.getItem("Uid"),
      getUrl: "",
      id_param: this.$route.params.id,
      tglSekarang: new Date()
    };
  },
  computed: {
    
  },
  async mounted() {
    this.checkAuth();
    await this.getPlan();
    this.getPlan2();
    this.getUrl = process.env.VUE_APP_URL;
    console.log(this.dataPlan2)
  },
  methods: {
    checkAuth() {
      var sessionCheck = localStorage.getItem("Uid");
      if (sessionCheck == null || sessionCheck == "") {
        this.$router.push({ name: "login" });
      }
    },
    async getPlan() {
      await this.$http
        // .get(process.env.VUE_APP_BACKEND_URL + "blogs/view")
        .get(
          process.env.VUE_APP_BACKEND_URL +
            `registerplans/view2/${this.id_session}`
        )
        .then((res) => {
          this.dataPlan = res.data.data;
        });
    },
    async getPlan2() {
      await this.$http
        .get(
          process.env.VUE_APP_BACKEND_URL +
            `registerplans/view5/${this.id_param}`
        )
        .then((res) => {
          this.dataPlan2 = res.data.data;
        });
    },
    formatDate(value) {
      moment.locale("id");
      if (value != null) {
        const val = moment(value).format("ll");
        return val;
      }
    },
    async addData(id_question, durasi) {
      const formData = new FormData();
      // formData.append("users_id", this.users_id);
      // formData.append("plans_id", this.plans_id);
      formData.append("users_id", this.id_session);
      formData.append("plans_id", id_question);
      formData.append("status", "Pending");
      await this.$http
        .post(process.env.VUE_APP_BACKEND_URL + "registerplans/create", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          // console.log("mikki", res.data.success);
          if (res.data.success == false) {
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Notification",
                  icon: "xIcon",
                  text: "Ada, Silahkan Masuk",
                  variant: "danger",
                },
              },
              {
                timeout: 5000,
              },
              {
                position: "top-right",
              }
            );
          } else {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Notification",
                icon: "CheckCircleIcon",
                text: "Sukses, Silahkan Masuk",
                variant: "success",
              },
            },
            {
              timeout: 5000,
            },
            {
              position: "top-right",
            }
          );
        }
          // this.getData()
            this.$router.push('/user/prepare_tryout/' +
            id_question +
              '/' +
              durasi)
        })
        .catch((err) => {
          console.log(err);
        });
    },
   
  },
};
</script>
